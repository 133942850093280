var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../../../nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});




var shim = require("/builds/natuurhuisje/nature.house/symfony/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["design-system/v3/components/atoms/input/Input.html.njk"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
var t_1;
t_1 = env.getFilter("merge").call(context, env.getFilter("default").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "input")),"classes"),[]),["nh-input-new"]);
frame.set("classes", t_1, true);
if(frame.topLevel) {
context.setVariable("classes", t_1);
}
if(frame.topLevel) {
context.addExport("classes", t_1);
}
if(env.getTest("defined").call(context, runtime.contextOrFrameLookup(context, frame, "icon")) === true && env.getFilter("default").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "icon")),"name"),"") != "") {
var t_2;
t_2 = env.getFilter("merge").call(context, runtime.contextOrFrameLookup(context, frame, "classes"),["nh-input-new__icon"]);
frame.set("classes", t_2, true);
if(frame.topLevel) {
context.setVariable("classes", t_2);
}
if(frame.topLevel) {
context.addExport("classes", t_2);
}
;
}
if(env.getFilter("default").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "input")),"isToggle"),false) != false) {
var t_3;
t_3 = env.getFilter("merge").call(context, runtime.contextOrFrameLookup(context, frame, "classes"),["nh-input-new--toggle"]);
frame.set("classes", t_3, true);
if(frame.topLevel) {
context.setVariable("classes", t_3);
}
if(frame.topLevel) {
context.addExport("classes", t_3);
}
;
}
if(env.getFilter("default").call(context, runtime.contextOrFrameLookup(context, frame, "minimal"),false) == true && env.getFilter("default").call(context, runtime.contextOrFrameLookup(context, frame, "label"),"") == "") {
var t_4;
t_4 = env.getFilter("merge").call(context, runtime.contextOrFrameLookup(context, frame, "classes"),["nh-input-new--show-placeholder"]);
frame.set("classes", t_4, true);
if(frame.topLevel) {
context.setVariable("classes", t_4);
}
if(frame.topLevel) {
context.addExport("classes", t_4);
}
;
}
output += "<input\n    class=\"";
output += runtime.suppressValue(env.getFilter("join").call(context, runtime.contextOrFrameLookup(context, frame, "classes")," "), env.opts.autoescape);
output += "\"\n    is=\"nh-input\"";
if(env.getFilter("default").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "input")),"type"),"") != "") {
output += " type=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "input")),"type"), env.opts.autoescape);
output += "\" ";
;
}
if(env.getFilter("default").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "input")),"id"),"") != "") {
output += " id=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "input")),"id"), env.opts.autoescape);
output += "\" ";
;
}
if(env.getFilter("default").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "input")),"placeholder"),"") != "") {
output += " placeholder=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "input")),"placeholder"), env.opts.autoescape);
output += "\" ";
;
}
if(env.getFilter("default").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "input")),"name"),"") != "") {
output += " name=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "input")),"name"), env.opts.autoescape);
output += "\" ";
;
}
if(env.getFilter("default").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "input")),"value"),"") != "") {
output += " value=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "input")),"value"), env.opts.autoescape);
output += "\" ";
;
}
if(env.getFilter("default").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "input")),"min"),"") != "") {
output += " min=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "input")),"min"), env.opts.autoescape);
output += "\" ";
;
}
if(env.getFilter("default").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "input")),"max"),"") != "") {
output += " max=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "input")),"max"), env.opts.autoescape);
output += "\" ";
;
}
if(env.getFilter("default").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "input")),"step"),"") != "") {
output += " step=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "input")),"step"), env.opts.autoescape);
output += "\" ";
;
}
if(env.getFilter("default").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "input")),"autocomplete"),"") != "") {
output += " autocomplete=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "input")),"autocomplete"), env.opts.autoescape);
output += "\" ";
;
}
if(env.getFilter("default").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "input")),"autofocus"),"") != "") {
output += " autofocus=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "input")),"autofocus"), env.opts.autoescape);
output += "\" ";
;
}
if(env.getFilter("default").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "input")),"minlength"),"") != "") {
output += " minlength=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "input")),"minlength"), env.opts.autoescape);
output += "\" ";
;
}
if(env.getFilter("default").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "input")),"maxlength"),"") != "") {
output += " maxlength=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "input")),"maxlength"), env.opts.autoescape);
output += "\" ";
;
}
if(env.getFilter("default").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "input")),"pattern"),"") != "") {
output += " pattern=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "input")),"pattern"), env.opts.autoescape);
output += "\" ";
;
}
if(env.getFilter("default").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "input")),"size"),"") != "") {
output += " size=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "input")),"size"), env.opts.autoescape);
output += "\" ";
;
}
if(env.getFilter("default").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "input")),"form"),"") != "") {
output += " form=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "input")),"form"), env.opts.autoescape);
output += "\" ";
;
}
if(env.getFilter("default").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "input")),"checked"),false) != false) {
output += " checked aria-checked=\"true\" ";
;
}
if(env.getFilter("default").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "input")),"multiple"),false) != false) {
output += " multiple ";
;
}
if(env.getFilter("default").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "input")),"readonly"),false) != false) {
output += " readonly ";
;
}
if(env.getFilter("default").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "input")),"required"),false) != false) {
output += " required ";
;
}
if(env.getFilter("default").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "input")),"disabled"),false) != false) {
output += " disabled ";
;
}
frame = frame.push();
var t_7 = env.getFilter("default").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "input")),"data"),{});
if(t_7) {t_7 = runtime.fromIterator(t_7);
var t_5;
if(runtime.isArray(t_7)) {
var t_6 = t_7.length;
for(t_5=0; t_5 < t_7.length; t_5++) {
var t_8 = t_7[t_5][0];
frame.set("[object Object]", t_7[t_5][0]);
var t_9 = t_7[t_5][1];
frame.set("[object Object]", t_7[t_5][1]);
frame.set("loop.index", t_5 + 1);
frame.set("loop.index0", t_5);
frame.set("loop.revindex", t_6 - t_5);
frame.set("loop.revindex0", t_6 - t_5 - 1);
frame.set("loop.first", t_5 === 0);
frame.set("loop.last", t_5 === t_6 - 1);
frame.set("loop.length", t_6);
output += " ";
output += runtime.suppressValue(t_8, env.opts.autoescape);
if(t_9) {
output += "=\"";
output += runtime.suppressValue(t_9, env.opts.autoescape);
output += "\"";
;
}
;
}
} else {
t_5 = -1;
var t_6 = runtime.keys(t_7).length;
for(var t_10 in t_7) {
t_5++;
var t_11 = t_7[t_10];
frame.set("name", t_10);
frame.set("value", t_11);
frame.set("loop.index", t_5 + 1);
frame.set("loop.index0", t_5);
frame.set("loop.revindex", t_6 - t_5);
frame.set("loop.revindex0", t_6 - t_5 - 1);
frame.set("loop.first", t_5 === 0);
frame.set("loop.last", t_5 === t_6 - 1);
frame.set("loop.length", t_6);
output += " ";
output += runtime.suppressValue(t_10, env.opts.autoescape);
if(t_11) {
output += "=\"";
output += runtime.suppressValue(t_11, env.opts.autoescape);
output += "\"";
;
}
;
}
}
}
frame = frame.pop();
if(env.getFilter("default").call(context, runtime.contextOrFrameLookup(context, frame, "errorText"),"") != "") {
output += "aria-describedby=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "input")),"id") + "" + "-error-text", env.opts.autoescape);
output += "\"";
;
}
else {
if(env.getFilter("default").call(context, runtime.contextOrFrameLookup(context, frame, "helpText"),"") != "") {
output += "aria-describedby=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "input")),"id") + "" + "-help-text", env.opts.autoescape);
output += "\"";
;
}
;
}
output += "/>\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["design-system/v3/components/atoms/input/Input.html.njk"] , dependencies)