var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../../../nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["@naturehouse/design-system/components/atoms/input/Input.html.njk"] = require( "@naturehouse/design-system/components/atoms/input/Input.html.njk" );
dependencies["@naturehouse/design-system/components/protons/icon/Icon.macro.njk"] = require( "@naturehouse/design-system/components/protons/icon/Icon.macro.njk" );
dependencies["@naturehouse/design-system/components/molecules/form-field/ErrorText.html.njk"] = require( "@naturehouse/design-system/components/molecules/form-field/ErrorText.html.njk" );
dependencies["@naturehouse/design-system/components/molecules/form-field/HelpText.html.njk"] = require( "@naturehouse/design-system/components/molecules/form-field/HelpText.html.njk" );




var shim = require("/builds/natuurhuisje/nature.house/symfony/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["design-system/v3/components/atoms/chip/Chip.html.njk"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
if(env.getTest("defined").call(context, runtime.contextOrFrameLookup(context, frame, "input")) === true && env.getFilter("default").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "input")),"id"),"") != "") {
var t_1;
t_1 = env.getFilter("merge").call(context, env.getFilter("default").call(context, runtime.contextOrFrameLookup(context, frame, "classes"),[]),["nh-chip"]);
frame.set("classes", t_1, true);
if(frame.topLevel) {
context.setVariable("classes", t_1);
}
if(frame.topLevel) {
context.addExport("classes", t_1);
}
var t_2;
t_2 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "input")),"id");
frame.set("htmlFor", t_2, true);
if(frame.topLevel) {
context.setVariable("htmlFor", t_2);
}
if(frame.topLevel) {
context.addExport("htmlFor", t_2);
}
if(env.getFilter("default").call(context, runtime.contextOrFrameLookup(context, frame, "helpText"),"") != "") {
var t_3;
t_3 = env.getFilter("merge").call(context, runtime.contextOrFrameLookup(context, frame, "classes"),["nh-chip--help"]);
frame.set("classes", t_3, true);
if(frame.topLevel) {
context.setVariable("classes", t_3);
}
if(frame.topLevel) {
context.addExport("classes", t_3);
}
;
}
if(env.getFilter("default").call(context, runtime.contextOrFrameLookup(context, frame, "errorText"),"") != "") {
var t_4;
t_4 = env.getFilter("merge").call(context, runtime.contextOrFrameLookup(context, frame, "classes"),["nh-chip--error"]);
frame.set("classes", t_4, true);
if(frame.topLevel) {
context.setVariable("classes", t_4);
}
if(frame.topLevel) {
context.addExport("classes", t_4);
}
;
}
output += "\n\n    <nh-chip\n        class=\"";
output += runtime.suppressValue(env.getFilter("join").call(context, runtime.contextOrFrameLookup(context, frame, "classes")," "), env.opts.autoescape);
output += "\"";
frame = frame.push();
var t_7 = env.getFilter("default").call(context, runtime.contextOrFrameLookup(context, frame, "data"),{});
if(t_7) {t_7 = runtime.fromIterator(t_7);
var t_5;
if(runtime.isArray(t_7)) {
var t_6 = t_7.length;
for(t_5=0; t_5 < t_7.length; t_5++) {
var t_8 = t_7[t_5][0];
frame.set("[object Object]", t_7[t_5][0]);
var t_9 = t_7[t_5][1];
frame.set("[object Object]", t_7[t_5][1]);
frame.set("loop.index", t_5 + 1);
frame.set("loop.index0", t_5);
frame.set("loop.revindex", t_6 - t_5);
frame.set("loop.revindex0", t_6 - t_5 - 1);
frame.set("loop.first", t_5 === 0);
frame.set("loop.last", t_5 === t_6 - 1);
frame.set("loop.length", t_6);
output += " ";
output += runtime.suppressValue(t_8, env.opts.autoescape);
if(t_9) {
output += "=\"";
output += runtime.suppressValue(t_9, env.opts.autoescape);
output += "\"";
;
}
;
}
} else {
t_5 = -1;
var t_6 = runtime.keys(t_7).length;
for(var t_10 in t_7) {
t_5++;
var t_11 = t_7[t_10];
frame.set("name", t_10);
frame.set("value", t_11);
frame.set("loop.index", t_5 + 1);
frame.set("loop.index0", t_5);
frame.set("loop.revindex", t_6 - t_5);
frame.set("loop.revindex0", t_6 - t_5 - 1);
frame.set("loop.first", t_5 === 0);
frame.set("loop.last", t_5 === t_6 - 1);
frame.set("loop.length", t_6);
output += " ";
output += runtime.suppressValue(t_10, env.opts.autoescape);
if(t_11) {
output += "=\"";
output += runtime.suppressValue(t_11, env.opts.autoescape);
output += "\"";
;
}
;
}
}
}
frame = frame.pop();
output += "\n    >";
var tasks = [];
tasks.push(
function(callback) {
env.getTemplate("@naturehouse/design-system/components/atoms/input/Input.html.njk", false, "design-system/v3/components/atoms/chip/Chip.html.njk", false, function(t_13,t_12) {
if(t_13) { cb(t_13); return; }
callback(null,t_12);});
});
tasks.push(
function(template, callback){
template.render(context.getVariables(), frame, function(t_15,t_14) {
if(t_15) { cb(t_15); return; }
callback(null,t_14);});
});
tasks.push(
function(result, callback){
output += result;
callback(null);
});
env.waterfall(tasks, function(){
output += "\n\n        <label is=\"nh-label\" for=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "htmlFor"), env.opts.autoescape);
output += "\">";
if(env.getTest("defined").call(context, runtime.contextOrFrameLookup(context, frame, "icon")) === true && env.getFilter("default").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "icon")),"name"),"") != "") {
env.getTemplate("@naturehouse/design-system/components/protons/icon/Icon.macro.njk", false, "design-system/v3/components/atoms/chip/Chip.html.njk", false, function(t_17,t_16) {
if(t_17) { cb(t_17); return; }
t_16.getExported(function(t_18,t_16) {
if(t_18) { cb(t_18); return; }
context.setVariable("iconTemplate", t_16);
output += "\n                ";
output += runtime.suppressValue((lineno = 22, colno = 38, runtime.callWrap(runtime.memberLookup((t_16),"render"), "iconTemplate[\"render\"]", context, [{"name": runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "icon")),"name"),"size": env.getFilter("default").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "icon")),"size"),"1.25"),"custom": env.getFilter("default").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "icon")),"custom"),false)}])), env.opts.autoescape);
})});
}
output += "<span class=\"nh-chip__label\">";
output += runtime.suppressValue(env.getFilter("default").call(context, runtime.contextOrFrameLookup(context, frame, "label"),""), env.opts.autoescape);
output += "</span>\n        </label>";
if(env.getFilter("default").call(context, runtime.contextOrFrameLookup(context, frame, "errorText"),"") != "") {
var tasks = [];
tasks.push(
function(callback) {
env.getTemplate("@naturehouse/design-system/components/molecules/form-field/ErrorText.html.njk", false, "design-system/v3/components/atoms/chip/Chip.html.njk", false, function(t_20,t_19) {
if(t_20) { cb(t_20); return; }
callback(null,t_19);});
});
tasks.push(
function(template, callback){
template.render(context.getVariables(), frame, function(t_22,t_21) {
if(t_22) { cb(t_22); return; }
callback(null,t_21);});
});
tasks.push(
function(result, callback){
output += result;
callback(null);
});
env.waterfall(tasks, function(){
});
}
if(env.getFilter("default").call(context, runtime.contextOrFrameLookup(context, frame, "helpText"),"") != "") {
var tasks = [];
tasks.push(
function(callback) {
env.getTemplate("@naturehouse/design-system/components/molecules/form-field/HelpText.html.njk", false, "design-system/v3/components/atoms/chip/Chip.html.njk", false, function(t_24,t_23) {
if(t_24) { cb(t_24); return; }
callback(null,t_23);});
});
tasks.push(
function(template, callback){
template.render(context.getVariables(), frame, function(t_26,t_25) {
if(t_26) { cb(t_26); return; }
callback(null,t_25);});
});
tasks.push(
function(result, callback){
output += result;
callback(null);
});
env.waterfall(tasks, function(){
});
}
output += "</nh-chip>";
});
}
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["design-system/v3/components/atoms/chip/Chip.html.njk"] , dependencies)